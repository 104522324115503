import React from 'react';
import injectSheet from 'react-jss';
import { styles } from './styles.js';

const SwicherTemplate = ({ classes, ...props }) => {
    const activeClass = props.selected;
    return (
        <div className={[classes.wrap, classes[activeClass]].join(' ')}>
            <button
                className={[classes.switchButton, classes.switchWinter].join(' ')}
                onClick={()=>props.changeSwicher('winter')}
            >Зима</button>
            <span className={classes.checkSlider} onClick={props.toggleSwicher}></span>
            <button
                className={[classes.switchButton, classes.switchSummer].join(' ')}
                onClick={()=>props.changeSwicher('summer')}
            >Лето</button>
        </div>
    )
}

export default injectSheet(styles)(SwicherTemplate);
