import React from 'react';
import { styles } from './styles';
import injectSheet from 'react-jss';
import Title from '../Title';
import TabChecker from '../../containers/TabChecker';
import TiresList from '../../containers/TiresList';
import Swicher from '../../containers/Swicher';

const HomeTemp = ({classes}) => {
    return(
        <React.Fragment>
            <div className={classes.title}>
                <Title
                    blackTitle = {true}
                >
                    ШИНЫ BRIDGESTONE
                </Title>
            </div>
            <TabChecker />
            <div className={classes.swicher}>
                <Swicher />
            </div>
            <TiresList />
        </React.Fragment>
    );
};

export default injectSheet(styles)(HomeTemp);
