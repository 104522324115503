
export const filterTires = (tires = [], season) => {
    try{
        return tires.reduce((newTires, tire) => {
            if(tire.season === season){
                newTires.push(tire);
            }
            return newTires;
        }, []);
    }
    catch(err){
        return tires;
    }
};

export const sortTiresBySort = (tires = []) => {
    try{
        return tires.sort((current, next) => {
            return Number(current.sort) - Number(next.sort);
        });
    }
    catch(err){
        return tires;
    }
};
