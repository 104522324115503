import React from 'react';
import HomeContainer from './containers/Home/index';
import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import { Provider } from 'react-redux';
import reducers from './reducers';
import Main from './containers/Main';

import './App.css';

const store = createStore(reducers, compose(
    applyMiddleware(thunk),
    window.__REDUX_DEVTOOLS_EXTENSION__ ? window.__REDUX_DEVTOOLS_EXTENSION__() : compose
));

function App() {
    return (
        <Provider store={store}>
            <Main />
            <HomeContainer />
        </Provider>
    );
}

export default App;
