import config from '../config';
import API from './API';
import Entity from './entity';

class Cities extends Entity {
    constructor(...props) {
        super(...props);
        this.storeEntity = 'cities';
    }
}

export default new Cities(API, config);
