import React from 'react';
import TabBodyTemp from './template';
import PropTypes from 'prop-types';

export default class TabBody extends React.Component {
    render() {
        return <TabBodyTemp items={this.props.items} handleChange={this.props.handleChange}/>;
    }
}

TabBody.propTypes = {
    items: PropTypes.array.isRequired,
    handleChange: PropTypes.func.isRequired
};
