
export const getFilterById = (id, filters = []) => {
    try{
        return filters.find(el => el.id === id) || null;
    }
    catch(err){
        return null;
    }
};

export const creteFilters = (filters = []) => {
    try{
        return filters.map((el, indexFilter) => {
            const options = el.items.map(el => {
                const option = {
                    value: indexFilter === 0 ? el.toLowerCase().replace(/ /g, '_') : el,
                    label: el
                };
                return {...option};
            });
            const placeholder = el.title;
            const point = el.point || '';
            const type = el.type || '';
            const id = el.id || '';
            const pushTo = el.pushTo || '';
            const link = el.link || '';
            const relationFilter = getFilterById(el.relationship, filters);
            const disabled = relationFilter && relationFilter.items.length === 0 ? true : false;
            const filter = {
                placeholder,
                options,
                disabled,
                point,
                id,
                type,
                pushTo,
                link
            };
            return {...filter};
        });
    }
    catch(err){
        return [];
    }
}
