import config from '../config';
import API from './API';
import Entity from './entity';

class News extends Entity {
    constructor(...props) {
        super(...props);
        this.storeEntity = 'news';
    }
}

export default new News(API, config);
