export const styles = {
    link: {
        color: '#020202',
        fontFamily: 'Arial, sans-serif',
        fontSize: 12,
        lineHeight: '35px',
        textDecoration: 'none',
        textTransform: 'uppercase',
        display: 'inline-block',
        padding: [0, 15],
        boxSizing: 'border-box',
        border: '2px solid #e6131b',
        transition: 'all 0.4s',
        '&:hover': {
            color: '#ffffff',
            background: '#e6131b'
        },
    },
    '@media screen and (max-width: 768px)': {
        link: {
            fontSize: '2.5vw',
            height: 'auto',
            padding: '2.1% 9px',
            lineHeight: 'normal'
        }
    }
};
