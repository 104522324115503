export const styles = {
    slider: {
        marginTop: 80,
        '& .slick-slide': {
            display: 'flex',
            height: 'auto',
            '& *': {
                outline: 'none'
            }
        },
        '& .slick-list': {
            overflow: 'visible'
        },
        '& .slick-dots': {
            transform: 'translateX(-17.5%)',
            '& li': {
                width: 36,
                height: 5,
                '& button': {
                    width: 36,
                    height: 5,
                    background: '#d3d3d3',
                    padding: 0,
                    '&:before': {
                        display: 'none'
                    }
                }
            },
            '& .slick-active': {
                '& button': {
                    background: '#e6131b'
                }
            }
        },
        '& .slick-track': {
            display: 'flex',
            flexWrap: 'wrap'
        }
    }
};
