import React from 'react';
import injectSheet from 'react-jss';
import { styles } from './styles.mobile.js';
import TiresItem from '../TiresItem';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1.2,
    slidesToScroll: 1,
    arrows: false
};

const TiresList = ({ classes, ...props }) => {

    return (
        <div className={classes.slider}>
            <Slider {...settings} afterChange={props.afterChange}>
                {props.items.map((el, index) => {
                    return (
                        <TiresItem
                            key={el.tyre_id}
                            link={el.detail_page_url}
                            content={
                                {
                                    title: el.categories,
                                    info: el.property_features_value
                                }
                            }
                            tireImageSrc={el.preview_picture}
                            logoSrc={el.logo_white ? el.logo_white : el.logo}
                            topImageSrc={el.banner}
                            index={index}
                            slideIndex={props.slideIndex}
                        />
                    );
                })}

            </Slider>
        </div>
    );
};

export default injectSheet(styles)(TiresList);
