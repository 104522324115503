import config from '../config';
import API from './API';
import Entity from './entity';

class Tires extends Entity {
    constructor(...props) {
        super(...props);
        this.storeEntity = 'tires';
    }
}

export default new Tires(API, config);
