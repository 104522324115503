import React from 'react';
import PropTypes from 'prop-types';
import Template from './template';

export default class TiresItem extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            isHover: false
        }
    }
    changeHover(){
        this.setState({
            isHover: !this.state.isHover
        })
    }
    render() {
        return <Template
            {...this.props}
            isHover={this.state.isHover}
            changeHover={this.changeHover.bind(this)}
        />;
    }
}

TiresItem.propTypes = {
    link: PropTypes.string,
    tireImageSrc: PropTypes.string,
    topImageSrc: PropTypes.string,
    logoSrc: PropTypes.string,
    logoHideSrc: PropTypes.string,
    index: PropTypes.number,
    content: PropTypes.shape({
        title: PropTypes.string.isRequired,
        info: PropTypes.array.isRequired,
    }),
}
