export const styles = {
    filter: {
        display: 'inline-block',
        verticalAlign: 'middle',
        marginLeft: '3.7%',
        width: '17%',
        '&:first-child': {
            marginLeft: 0
        }
    },
    filterWrap: {
        background: '#f5f5f5',
        padding: ['1%', '1.2%', '1.6%'],
        boxSizing: 'border-box',
        width: '100%',
        fontSize: 0
    },
    '@media screen and (max-width: 1000px)': {
        filter: {
            marginLeft: '2%',
            width: '18%',
        }
    },
    '@media screen and (max-width: 768px)': {
        filterWrap: {
            padding: [5, '4.5%', 12],
            display: 'flex',
            flexWrap: 'wrap',
            justifyContent: 'space-between'
        },
        filter: {
            margin: 0,
            width: '47%',
            marginTop: 12
        }
    }
};
