import React from 'react';
import injectSheet from 'react-jss';
import { styles } from './styles.js';
import TiresItem from '../TiresItem';
import LoadMore from '../LoadMore';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    arrows: true
};

const TiresList = ({ classes, ...props }) => {
    return (
        <React.Fragment>
            <div className={[classes.tiresWrap, classes.pointsImgR].join(' ')}>
                <Slider {...settings}>
                    {
                        props.items.map(el => {
                            return (
                                <div className={classes.tires} key={el.tyre_id}>
                                    <TiresItem
                                        link={el.detail_page_url}
                                        content={
                                            {
                                                title: el.categories,
                                                info: el.property_features_value,
                                                season: el.season
                                            }
                                        }
                                        tireImageSrc={el.preview_picture}
                                        logoSrc={el.logo_white ? el.logo_white : el.logo}
                                        logoHideSrc={el.logo_hide ? el.logo_hide : el.logo_white || el.logo}
                                        topImageSrc={el.banner}
                                        index={0}
                                    />
                                </div>
                            );
                        })
                    }
                </Slider>
            </div>
            <div className={classes.showAll}>
                <LoadMore
                    link={'/catalog/'}
                >
                    Все шины
                </LoadMore>
            </div>
        </React.Fragment>
    );
};

export default injectSheet(styles)(TiresList);
