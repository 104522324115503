import tiresService from '../services/tires';
export const SET_TIRES = 'SET_TIRES';
export const SET_SEASON = 'SET_SEASON';
export const FETCH_TIRES_START = 'FETCH_TIRES_START';
export const FETCH_TIRES_SUCCESS = 'FETCH_TIRES_SUCCESS';
export const FETCH_TIRES_FAILURE = 'FETCH_TIRES_FAILURE';

export const fetchTires = () => async dispatch => {
    dispatch({ type: FETCH_TIRES_START });
    try {
        const tyres = await tiresService.get();
        dispatch({
            type: FETCH_TIRES_SUCCESS,
            payload: tyres.result,
        });
        return tyres.result;
    } catch (error) {
        dispatch({
            type: FETCH_TIRES_FAILURE,
            payload: error
        });
    }
};


export const setTires = (tires = []) => dispatch => {
    dispatch({
        type: SET_TIRES,
        payload: tires,
    });
};

export const setSeason = season => dispatch => {
    dispatch({
        type: SET_SEASON,
        payload: season,
    });
};
