import citiesService from '../services/cities';
export const FETCH_CITIES_START = 'FETCH_CITIES_START';
export const FETCH_CITIES_SUCCESS = 'FETCH_CITIES_SUCCESS';
export const FETCH_CITIES_FAILURE = 'FETCH_CITIES_FAILURE';

export const fetchCities = () => async dispatch => {
    dispatch({ type: FETCH_CITIES_START });
    try {
        const formData = new FormData();
        formData.append('MODE', 'kodix:json');
        formData.append('CITY_ID', '1');
        const result = await citiesService.post(formData);
        const cities = result.map(city => {
            let label = city.title;
            if(city.country){
                label = `${city.country.title}, ${city.title}`;
            }
            return {
                ...city,
                label
            };
        });
        dispatch({
            type: FETCH_CITIES_SUCCESS,
            payload: cities,
        });
        return cities;
    } catch (error) {
        dispatch({
            type: FETCH_CITIES_FAILURE,
            payload: error
        });
    }
};
