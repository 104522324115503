import React from 'react';
import injectSheet from 'react-jss';
import { styles } from './styles.js';

const LoadMoreTemplate = ({ classes, children, ...props }) => {
    return (
        <a href={props.link} className={classes.link}>
            {children}
        </a>
    );
};

export default injectSheet(styles)(LoadMoreTemplate);
