export const UPDATE_FILTER_ITEMS = 'UPDATE_FILTER_ITEMS';

export const updateFilterItems = (filters, type, id, pushTo, items) => dispatch => {
    const filterByType = filters[type];
    let clear = false;
    const newFilters = filterByType.map(filter => {
        const newItems = filter.idDb === pushTo ? items : clear ? [] : filter.items;
        if(filter.id === id){
            clear = true; // все следующие фыльтры очищаем
        }
        return {
            ...filter,
            items: newItems
        };
    });
    dispatch({
        type: UPDATE_FILTER_ITEMS,
        payload: {
            ...filters,
            [type]: [...newFilters]
        },
    });
    return newFilters;
};
