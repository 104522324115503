export const styles = {
    title: {
        fontSize: 30,
        textTransform: 'uppercase',
        color: props => props.styleParams.textColor,
        fontWeight: props => props.styleParams.textWeight,
        fontFamily: 'Arial, sans-serif',
        position: 'relative',
        '&:after': {
            content: '""',
            display: 'block',
            width: 36,
            height: 4,
            background: props => props.styleParams.lineBg,
            position: 'absolute',
            left: 0,
            bottom: -17
        }
    },
    '@media screen and (max-width: 768px)': {
        title: {
            fontSize: '6.1vw'
        }
    }
};