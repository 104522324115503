import React, { Component } from 'react';
import FilterSelectTemp from './template';
import PropTypes from 'prop-types';

export default class FilterSelect extends Component{
    render(){
        return(
            <FilterSelectTemp
                placeholder={this.props.placeholder}
                options={this.props.options}
                handleChange={this.props.handleChange}
                disabled={this.props.disabled}
                point={this.props.point}
                id={this.props.id}
                type={this.props.type}
                pushTo={this.props.pushTo}
                link={this.props.link}
            />
        );
    }
}

FilterSelect.propTypes = {
    placeholder: PropTypes.string.isRequired,
    options: PropTypes.array.isRequired,
    handleChange: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
    point: PropTypes.string,
    id: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    pushTo: PropTypes.string,
    link: PropTypes.string,
};

FilterSelect.defaultProps = {
    disabled: false,
    point: '',
    pushTo: '',
    link: '',
};
