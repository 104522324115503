import { BASE_PATH } from '../../const';

export const styles = {
    backgroundVideo: {
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        backgroundColor: 'rgba(0,0,0,0.6)',
        zIndex: 4
    },
    fixedHead: {
        paddingTop: 35
    },
    contentWrap: {
        margin: '0 auto',
        position: 'relative',
        width: '91%'
    },
    sliderContentWrap: {
        transform: 'translateX(17.5%)'
    },
    sliderOverflow: {
        overflow: 'hidden',
        paddingBottom: 35
    },
    hotLineContent: {
        boxSizing: 'border-box',
        paddingLeft: 130
    },
    hotToRight: {
        float: 'right',
        marginTop: 7
    },
    bigImgWrap: {
        background: `url(${BASE_PATH}/img/home_big_img.jpg) no-repeat`,
        backgroundSize: 'cover',
        boxShadow: 'inset 0px 57px 14px -18px rgba(0,0,0,0.25)',
        padding: [25, 0, 110],
        boxSizing: 'border-box',
    },
    menuWrap: {
        textAlign: 'right'
    },
    carSelect: {
        display: 'inline-block',
        verticalAlign: 'middle'
    },
    buttonWrap: {
        display: 'inline-block',
        verticalAlign: 'middle',
        marginLeft: 35
    },
    searchWrap: {
        display: 'inline-block',
        verticalAlign: 'middle',
        width: 18,
        marginLeft: 30
    },
    homeNavigationBar: {
        marginTop: 15
    },
    brandWrap: {
        padding: [20, '19.5%'],
        background: 'rgba(0, 0, 0, 0.4)',
        position: 'absolute',
        width: '100%',
        top: '50%',
        transform: 'translateY(-50%)'
    },
    searchShopWrap: {
        margin: '8px 0'
    },
    learnMoreWrap: {
        position: 'relative'
    },
    learnMore: {
        textAlign: 'right',
        marginTop: 18
    },
    menu: {
        display: 'inline-block',
        verticalAlign: 'middle',
        width: '57%'
    },
    title: {
        margin: [0, 0, 30]
    },
    swicher: {
        margin: [65, 0, 0],
        textAlign: 'center'
    },
    tiresWrap: {
        position: 'relative',
        fontSize: 0,
        marginTop: 35,
        display: 'flex',
    },
    tires: {
        display: 'inline-block',
        verticalAlign: 'top',
        width: '24%',
        marginLeft: '1.3%',
        '&:first-child': {
            marginLeft: 0
        }
    },
    showAll: {
        margin: [20, 0, 20],
        textAlign: 'center'
    },
    pointImgL: {
        position: 'relative',
        '&:after': {
            content: '""',
            display: 'block',
            background: `url(${BASE_PATH}/img/pointImg.png)`,
            width: 283,
            height: 231,
            position: 'absolute',
            top: -41,
            left: -135,
            zIndex: -1
        }
    },
    aboutWrap: {
        width: '100%',
        marginTop: 45,
        background: '#012b39',
        padding: [35, 0, 30],
        boxSizing: 'border-box',
        position: 'relative',
        zIndex: 2
    },
    about: {
        width: '100%',
        display: 'inline-block',
        textAlign: 'left',
        boxSizing: 'border-box'
    },
    video: {
        width: '100%',
        position: 'relative',
        zIndex: 1
    },
    secondBanner: {
        marginTop: 32
    },
    bigPointBlock: {
        background: `url(${BASE_PATH}/img/pointImg.png)`,
        height: 30,
    },
    progressBlock: {
        padding: [25, 0, 40],
        boxSizing: 'border-box',
        background: '#012b39',
        marginTop: 40,
        textAlign: 'center'
    },
    progressTitle: {
        display: 'inline-block',
        marginBottom: 40
    },
    progressBlocks: {
        display: 'flex',
        justifyContent: 'space-between',
        flexWrap: 'wrap',
        textAlign: 'left'
    },
    progressItem: {
        width: '100%',
        marginTop: 20
    },
    news: {
        display: 'flex',
        justifyContent: 'space-between',
        flexWrap: 'wrap'
    },
    newsItem: {
        width: '31%'
    },
    subscription: {
        background: `url(${BASE_PATH}/img/pointImg.png)`,
        boxSizing: 'border-box',
        padding: [15, 0, 30],
        marginTop: 20
    },
    subscriptionBlock: {
        background: '#012b39'
    },
    footer: {
        marginTop: 15,
        borderTop: '1px solid #e6e8ea',
        borderBottom: '1px solid #e6e8ea',
        padding: [0, 0, 22],
        boxSizing: 'border-box'
    },
    footerMenuWrap: {
        display: 'flex',
        justifyContent: 'space-between',
        flexWrap: 'wrap',
    },
    footerMenu: {
        width: '43%',
        marginTop: 27
    },
    social: {
        marginTop: 35
    },
    afterFooterInform: {
        margin: [25, 0, 100],
        display: 'flex',
        justifyContent: 'space-between',
        flexWrap: 'wrap'
    },
    afterFooterL: {
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
        flexWrap: 'wrap',
        padding: [15, 0, 25]
    },
    afterFooterLink: {
        color: '#0b2431',
        textDecoration: 'none',
        fontSize: '2.6vw',
        display: 'block',
        marginTop: 3,
        '&:first-child': {
            marginTop: 0
        }
    },
    LKWrap: {
        width: '14%'
    },
    mobBigImg: {
        position: 'relative',
    },
    afterFooterColl: {
        width: '43%'
    }
};
