import PropTypes from 'prop-types';
import React from 'react';
import Template from './template';

export default class Swicher extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            selected: props.selected
        };
    }

    toggleSwicher() {
        const value = this.state.selected === 'winter' ? 'summer' : 'winter';
        this.setState({
            selected: value
        });
        this.props.onChange(value);
    }

    changeSwicher(value) {
        if(value === this.state.selected){
            return false
        }
        this.setState({
            selected: value
        });
        this.props.onChange(value);
    }

    render() {
        return <Template
            selected={this.state.selected}
            toggleSwicher={this.toggleSwicher.bind(this)}
            changeSwicher={this.changeSwicher.bind(this)}
        />;
    }
}

Swicher.propTypes = {
    selected: PropTypes.oneOf(['winter', 'summer']),
    onChange: PropTypes.func.isRequired,
};

Swicher.defaultProps = {
    selected: 'winter',
};
