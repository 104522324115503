import shopsService from '../services/shops';
export const FETCH_SHOPS_START = 'FETCH_SHOPS_START';
export const FETCH_SHOPS_SUCCESS = 'FETCH_SHOPS_SUCCESS';
export const FETCH_SHOPS_FAILURE = 'FETCH_SHOPS_FAILURE';

export const fetchShops = () => async dispatch => {
    dispatch({ type: FETCH_SHOPS_START });
    try {
        const formData = new FormData();
        formData.append('MODE', 'kodix:button.location');
        const shops = await shopsService.post(formData);
        dispatch({
            type: FETCH_SHOPS_SUCCESS,
            payload: shops.map(shop => {
                let label = shop.title;
                if(shop.country){
                    label = `${shop.country.title}, ${shop.title}`;
                }
                return {
                    ...shop,
                    label
                };
            })
        });
    } catch (error) {
        dispatch({
            type: FETCH_SHOPS_FAILURE,
            payload: error
        });
    }
};
