import React from 'react';
import PropTypes from 'prop-types';
import Template from './template';
import TemplateMobile from './template.mobile';
import constants from '../../const';

export default class TiresList extends React.Component {
    constructor(props){
        super(props);

        this.state = {
            slideIndex: 0
        };
    }

    afterChange(index){
        this.setState({
            slideIndex: index
        });
    }

    render() {
        if(this.props.mode === constants.MOBILE){
            return <TemplateMobile items={this.props.items} slideIndex={this.state.slideIndex} afterChange={this.afterChange.bind(this)}/>;
        }
        return <Template items={this.props.items}/>;
    }
}

TiresList.propTypes = {
    items: PropTypes.array.isRequired,
    mode: PropTypes.string
};

TiresList.defaultPropd = {
    mode: 'desktop'
};
