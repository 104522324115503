export const styles = {
    tab: {
        display: 'inline-block',
        verticalAlign: 'middle',
        fontFamily: 'Arial, sans-serif',
        color: '#000',
        fontSize: 20,
        textTransform: 'uppercase',
        background: '#f5f5f5',
        width: '50%',
        textAlign: 'center',
        height: 60,
        lineHeight: '60px',
        outline: 'none',
        border: '1px solid #fff',
        cursor: 'pointer',
        boxSizing: 'border-box',
        position: 'relative'
    },
    active: {
        background: '#e6131b',
        color: '#fff',
        '&:after': {
            content: '""',
            width: 0,
            height: 0,
            border: '9px solid transparent',
            borderTopColor: '#e6131b',
            position: 'absolute',
            top: '100%',
            left: '50%',
            marginLeft: -9,
            zIndex: 1
        }
    },
    loadMore: {
        'text-align': 'center',
        'margin': [20, 0]
    },
    '@media screen and (max-width: 1000px)': {
        tab: {
            width: '50%'
        }
    },
    '@media screen and (max-width: 768px)': {
        tab: {
            height: 'auto',
            fontSize: '3.5vw',
            lineHeight: 'normal',
            padding: ['4%', 0],
        }
    }
};
