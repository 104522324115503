import React from 'react';
import { connect } from 'react-redux';
import TabChecker from '../../components/TabChecker';
import TabBody from '../../components/TabBody';
import { creteFilters } from '../../helpers/filters';
import { updateFilterItems } from '../../actions/filters';
import { setTires } from '../../actions/tires';
import tiresService from '../../services/tires';

class TabCheckerContainer extends React.Component {

    constructor(){
        super();
        this.selectedFilters = {
            car: [],
            tyre: []
        };
        this.defaultLink = '/catalog/';
        this.state = {
            link: this.defaultLink
        };
    }

    fillFilters(type, id, value, link){
        const currentFilter = {id, value, link};
        const filter = this.selectedFilters[type];
        const indexCurrentFilter = filter.findIndex(el => el.id === id);
        if(indexCurrentFilter === -1){
            filter.push(currentFilter);
            return;
        }
        else{
            filter[indexCurrentFilter] = currentFilter;
            const length = filter.length;
            filter.splice(indexCurrentFilter + 1, length - indexCurrentFilter);
        }
        this.selectedFilters[type] = filter;
    }

    setLink(type){
        let {link} = this.state;
        const filter = this.selectedFilters[type];
        if(type === 'car'){
            link = filter.reduce((acc, item) => {
                return acc += `${item.value}/`;
            }, `${this.defaultLink}car/`);
        }
        else if(type === 'tyre'){
            link = filter.reduce((acc, item) => {
                if(item.link.length === 0){
                    return acc;
                }
                return acc += `${item.link}${item.value}-`;
            }, `${this.defaultLink}`);
            link = `${link.substr(0, link.length - 1)}/`;
        }

        this.setState({
            link
        });
    }

    async handleChangeSelect(value, id, type, point, pushTo, link = ''){
        this.fillFilters(type, id, value.value, link);
        this.setLink(type, value.value);
        const filters = this.selectedFilters[type].reduce((acc, el) => {
            const {id, value} = el;
            return {
                ...acc,
                [id]: value
            };
        }, {});
        const result = await tiresService.get({type, ...filters}, `/${type}s${point}`);
        if(!result || Object.keys(result).length === 0){
            return;
        }
        const itemsFilter = result[pushTo];
        if(typeof itemsFilter !== 'undefined'){
            this.props.updateFilterItems(this.props.filters, type, id, pushTo, itemsFilter);
        }
    }

    render() {
        const {car: carsFilter, tyre: tiresFilter} = this.props.filters;
        const itemsFiltersCars = creteFilters(carsFilter);
        const itemsFiltersTires = creteFilters(tiresFilter);
        return (
            <React.Fragment>
                <TabChecker tabs={['ПО АВТОМОБИЛЮ', 'ПО РАЗМЕРУ ШИН']} link={this.state.link}>
                    <TabBody
                        items={itemsFiltersCars}
                        handleChange={this.handleChangeSelect.bind(this)}
                    />
                    <TabBody
                        items = {itemsFiltersTires}
                        handleChange={this.handleChangeSelect.bind(this)}
                    />
                </TabChecker>
            </React.Fragment>
        );
    }
}

const mapDispatchToProps = {
    updateFilterItems,
    setTires
};

const mapStateToProps = state => ({
    mode: state.options.mode,
    filters: state.filters.data
});

export default connect(mapStateToProps, mapDispatchToProps)(TabCheckerContainer);
