import React from 'react';
import injectSheet from 'react-jss';
import LoadMore from '../LoadMore';
import { styles } from './styles.js';

const TabCheckerTemp = ({ classes, ...props }) => {
    return(
        <React.Fragment>
            {
                props.tabs.map((tab, index) => {
                    return(
                        <button
                            key={index}
                            onClick={props.onClick.bind(null, index)}
                            className={[classes.tab, index === props.selected ? classes.active : ' '].join(' ')}
                        >
                            {tab}
                        </button>
                    );
                })
            }
            {props.children[props.selected]}
            <div className={classes.loadMore}>
                <LoadMore link={props.link}>
                    Показать результаты
                </LoadMore>
            </div>
        </React.Fragment>
    );
};

export default injectSheet(styles)(TabCheckerTemp);
