import React from 'react';
import { connect } from 'react-redux';
import TiresList from '../../components/TiresList';
import { filterTires, sortTiresBySort } from '../../helpers/tires';
import { fetchTires } from '../../actions/tires';

class TiresListContainer extends React.Component {

    componentDidMount(){
        this.props.fetchTires();
    }

    render() {
        if(this.props.items.length === 0){
            return null;
        }
        return <TiresList
            items={sortTiresBySort(filterTires(this.props.items, this.props.season))}
            season={this.props.season}
            mode={this.props.mode}
        />;
    }
}

const mapStateToProps = state => ({
    items: state.tires.data,
    season: state.tires.season,
    mode: state.options.mode,
});

const mapDispatchToProps = {
    fetchTires
};

export default connect(mapStateToProps, mapDispatchToProps)(TiresListContainer);
