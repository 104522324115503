import React, { Component } from 'react';
import TabCheckerTemp from './template';
import PropTypes from 'prop-types';

export default class TabChecker extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selected: 0
        };
    }

    onClick(index) {
        this.setState({
            selected: index
        });
    }

    render() {
        return(
            <TabCheckerTemp
                onClick={this.onClick.bind(this)}
                selected={this.state.selected}
                tabs={this.props.tabs}
                children={this.props.children}
                link={this.props.link}
            />
        );
    }
}

TabChecker.propTypes = {
    tabs: PropTypes.array.isRequired,
    link: PropTypes.string,
};

TabChecker.defaultProps = {
    link: '#',
};
