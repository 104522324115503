import { BASE_PATH } from '../../const';
export const styles = {
    filterSelect: {
        fontFamily: 'Arial, sans-serif',
        pointerEvents: props => props.disabled || props.options.length === 0 ? 'none' : 'all',
        opacity: props => props.disabled || props.options.length === 0 ? 0.7 : 1,
        '& .css-yk16xz-control': {
            height: 40,
            borderRadius: 0,
            border: '1px solid #cdcecd',
            cursor: 'pointer',
            '&:hover': {
                border: '1px solid #cdcecd'
            }
        },
        '& .css-1wa3eu0-placeholder': {
            textTransform: 'uppercase',
            color: '#8d8f8d',
            fontSize: 14,
            fontFamily: 'Arial, sans-serif',
        },
        '& .css-1hwfws3': {
            paddingLeft: 12,
            fontSize: 14
        },
        '& .css-1okebmr-indicatorSeparator': {
            display: 'none'
        },
        '& .css-tlfecz-indicatorContainer, & .css-1gtu0rj-indicatorContainer': {
            background: `url(${BASE_PATH}/img/select_arrow.jpg) no-repeat`,
            width: 42,
            height: 40,
            position: 'absolute',
            right: -1,
            top: -1,
            '& svg': {
                display: 'none'
            }
        },
        '& .css-1pahdxg-control': {
            border: '1px solid #cdcecd',
            borderRadius: 0,
            boxShadow: 'none',
            height: 40,
            '&:hover': {
                border: '1px solid #cdcecd'
            },
            '& .css-1wa3eu0-placeholder': {
                color: '#000'
            }

        },
        '& .css-26l3qy-menu': {
            margin: 0,
            borderRadius: 0,
            boxSizing: 'border-box',
            boxShadow: 'none',
            border: '1px solid #cdcecd',
            borderTop: 'none'
        },
        '& .css-1n7v3ny-option, & .css-yt9ioa-option, & .css-9gakcf-option': {
            background: 'none',
            fontSize: 12,
            boxSizing: 'border-box',
            padding: '5px 0 5px 10px',
            fontFamily: 'Arial, sans-serif',
            cursor: 'pointer',
            color: '#000'
        },
        '& .css-1ml51p6-MenuList': {
            padding: '10px 0'
        }
    }
};
