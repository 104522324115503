import React, { Component } from 'react';
import TitleTemp from './template';
import PropTypes from 'prop-types';

export default class Title extends Component{
    constructor(props){
        super(props);

        if(props.blackTitle){
            this.styleParams = {
                textColor : '#000',
                lineBg : '#e6131b',
                textWeight: 'normal'
            }
        }else {
            this.styleParams = {
                textColor : '#fff',
                lineBg : '#fff',
                textWeight: 'bold'
            }
        }
    }
    render(){
        return(
            <TitleTemp
                blacktitle = {this.props.blackTitle}
                styleParams = {this.styleParams}
                children = {this.props.children}
            />
        )
    }
}

Title.defaultProps = {
    blackTitle: false
};
Title.propTypes = {
    blackTitle : PropTypes.bool
};