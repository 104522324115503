import {
    SET_APP_MODE,
    CHANGE_VISIBILITY_MENU,
    CHANGE_VISIBILITY_CITY_MENU,
    CHANGE_VISIBILITY_SEARCH,
    CHANGE_VISIBILITY_VIDEO,
    SET_VISIBILITY_POPUP_TERMS,
    SET_CITY,
    SET_VISIBILITY_POPUP_PRIVACY,
    SET_SCROLL_MODE
} from './../actions/options';

const initialState = {
    mode: '',
    city: '',
    menuVisibility: false,
    cityInputVisibility: false,
    searchVisibility: false,
    videoVisibility: false,
    visibilityPopupTerms: false,
    visibilityPopupPrivacy: false,
    fixedHead: false
};

export default (state = initialState, action) => {
    switch (action.type) {
    case SET_APP_MODE:
        return {
            ...state,
            mode: action.payload
        };
    case SET_SCROLL_MODE:
        return {
            ...state,
            fixedHead: action.payload
        };
    case CHANGE_VISIBILITY_MENU:
        return {
            ...state,
            menuVisibility: action.payload
        };
    case CHANGE_VISIBILITY_CITY_MENU:
        return {
            ...state,
            cityInputVisibility: action.payload
        };
    case CHANGE_VISIBILITY_SEARCH:
        return {
            ...state,
            searchVisibility: action.payload
        };
    case CHANGE_VISIBILITY_VIDEO:
        return {
            ...state,
            videoVisibility: action.payload
        };
    case SET_CITY:
        return {
            ...state,
            city: action.payload
        };
    case SET_VISIBILITY_POPUP_TERMS:
        return {
            ...state,
            visibilityPopupTerms: action.payload
        };
    case SET_VISIBILITY_POPUP_PRIVACY:
        return {
            ...state,
            visibilityPopupPrivacy: action.payload
        };
    default:
        return state;
    }
};
