import md5 from 'md5';
import fetch from 'cross-fetch';
import { localStorePrefix } from '../const';

const WAS_EXPIRED = 'Data was expired';

function getCurrentTime() {
    return new Date().getTime();
}

const expireCache = 1000 * 60 * 60; // 1 hour

class API {
    constructor(urlAPI, storage) {
        this.urlAPI = urlAPI;
        this.storage = storage;
    }

    getUrlAPI(key, queryURI = '', point = '') {
        return (this.urlAPI[key] ? this.urlAPI[key] : '/') + point + (queryURI.toString() ? `?${queryURI}` : '');
    }

    async post(entity, data) {
        return fetch(this.getUrlAPI(entity), {
            method: 'POST',
            body: data,
        }).then(async response => {
            return await response.json();
        }).catch(() => null);
    }

    async get(entity, query, point = '') {
        const queryURI = this.makeURIParams(query);
        const key = this.makeQueryKey(entity, query, point);
        try {
            return await this.getLocalCopy(key);
        } catch (error) {
            return fetch(this.getUrlAPI(entity, queryURI, point)).then(async response => {
                try {
                    const data = await response.json();
                    const dataJSON = {
                        data,
                        meta: {
                            requestDate: getCurrentTime()
                        }
                    };
                    await this.setLocalCopy(key, JSON.stringify(dataJSON));
                    return data;
                } catch (error) {
                    return null;
                }
            });
        }
    }

    makeQueryKey(entity, query, point = '') {
        return entity + '_' + point + md5(this.makeURIParams(query).toString());
    }

    makeStorageKey(entity) {
        return `${localStorePrefix}${entity}`;
    }

    getLocalCopy(entity) {
        return new Promise((resolve, reject) => {
            const raw = this.storage.getItem(this.makeStorageKey(entity));
            try {
                const JSONData = JSON.parse(raw);
                const { meta: { requestDate } } = JSONData;
                const now = getCurrentTime();
                const DiffTime = now - requestDate;
                if (DiffTime >= expireCache) {
                    this.purgeStorageByKey(entity);
                    reject(WAS_EXPIRED);
                }
                resolve(JSONData.data);
            } catch (error) {
                reject(error);
            }
        });
    }

    setLocalCopy(entity, data) {
        return new Promise((resolve, reject) => {
            try {
                this.storage.setItem(this.makeStorageKey(entity), data);
                resolve(data);
            } catch (error) {
                reject(error);
            }
        });
    }

    makeURIParams(query) {
        const data = Object.entries(query);
        return new URLSearchParams(data.reduce((acc, el) => {
            acc.push(el.join('='));
            return acc;
        }, []).join('&'));
    }

    purgeStorageByKey(key) {
        if (key.indexOf(localStorePrefix) !== -1) {
            this.storage.removeItem(key);
        }
    }

    purgeStorage() {
        const keys = Object.keys(localStorage);
        try {
            keys.map(key => {
                this.purgeStorageByKey(key);
                return key;
            });
            return true;
        } catch (error) {
            return false;
        }
    }

}

export default API;
