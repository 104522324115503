export const SET_APP_MODE = 'SET_APP_MODE';
export const CHANGE_VISIBILITY_MENU = 'CHANGE_VISIBILITY_MENU';
export const CHANGE_VISIBILITY_CITY_MENU = 'CHANGE_VISIBILITY_CITY_MENU';
export const CHANGE_VISIBILITY_SEARCH = 'CHANGE_VISIBILITY_SEARCH';
export const CHANGE_VISIBILITY_VIDEO = 'CHANGE_VISIBILITY_VIDEO';
export const SET_CITY = 'SET_CITY';
export const SET_VISIBILITY_POPUP_TERMS = 'SET_VISIBILITY_POPUP_TERMS';
export const SET_VISIBILITY_POPUP_PRIVACY = 'SET_VISIBILITY_POPUP_PRIVACY';
export const SET_SCROLL_MODE = 'SET_SCROLL_MODE';

export const setAPPMode = mode => dispatch => {
    dispatch({
        type: SET_APP_MODE,
        payload: mode
    });
};

export const setScrollMode = value => dispatch => {
    dispatch({
        type: SET_SCROLL_MODE,
        payload: value
    });
};

export const changeVisibilityMenu = value => dispatch => {
    dispatch({
        type: CHANGE_VISIBILITY_MENU,
        payload: value
    });
};

export const changeVisibilityCityMenu = value => dispatch => {
    dispatch({
        type: CHANGE_VISIBILITY_CITY_MENU,
        payload: value
    });
};

export const changeVisibilitySearch = value => dispatch => {
    dispatch({
        type: CHANGE_VISIBILITY_SEARCH,
        payload: value
    });
};

export const changeVisibilityVideo = value => dispatch => {
    dispatch({
        type: CHANGE_VISIBILITY_VIDEO,
        payload: value
    });
};

export const setCity = city => dispatch => {
    dispatch({
        type: SET_CITY,
        payload: city
    });
};

export const setVisibilityPopupTerms = (visibility = false) => dispatch => {
    dispatch({
        type: SET_VISIBILITY_POPUP_TERMS,
        payload: visibility
    });
};

export const setVisibilityPopupPrivacy = (visibility = false) => dispatch => {
    dispatch({
        type: SET_VISIBILITY_POPUP_PRIVACY,
        payload: visibility
    });
};
