import {
    SET_TIRES,
    SET_SEASON,
    FETCH_TIRES_SUCCESS
} from './../actions/tires';
// import { defaultTires } from '../const.filters';

const initialState = {
    onload: false,
    error: null,
    season: 'summer',
    // data: [...defaultTires],
    data: [],
};

export default (state = initialState, action) => {
    switch (action.type) {
    case SET_TIRES:
    case FETCH_TIRES_SUCCESS:
        return {
            ...state,
            data: action.payload
        };
    case SET_SEASON:
        return {
            ...state,
            season: action.payload
        };
    default:
        return state;
    }
};
