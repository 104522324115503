import { combineReducers } from 'redux';
import options from './options';
import cities from './cities';
import shops from './shops';
import tires from './tires';
import filters from './filters';
import news from './news';
import topBanner from './topBanner';

export default combineReducers({
    options,
    cities,
    shops,
    tires,
    filters,
    news,
    topBanner
});
