import React from 'react';
import PropTypes from 'prop-types';
import Template from './template';

export default class LoadMore extends React.Component {
    render() {
        return <Template
            link={this.props.link}
            children={this.props.children}
        />;
    }
}

LoadMore.propTypes = {
    link: PropTypes.string,
}
