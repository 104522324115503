import {
    FETCH_TOP_BANNER_START,
    FETCH_TOP_BANNER_SUCCESS,
    FETCH_TOP_BANNER_FAILURE
} from './../actions/topBanner';

const initialState = {
    onload: false,
    error: null,
    data: {}
};

export default (state = initialState, action) => {
    switch (action.type) {
    case FETCH_TOP_BANNER_START:
        return {
            ...state,
            onload: true
        };
    case FETCH_TOP_BANNER_SUCCESS:
        return {
            ...state,
            data: action.payload
        };
    case FETCH_TOP_BANNER_FAILURE:
        return {
            ...state,
            onload: false
        };
    default:
        return state;
    }
};
