import {
    FETCH_SHOPS_START,
    FETCH_SHOPS_SUCCESS,
    FETCH_SHOPS_FAILURE
} from './../actions/shops';

const initialState = {
    onload: false,
    error: null,
    data: []
};

export default (state = initialState, action) => {
    switch (action.type) {
    case FETCH_SHOPS_START:
        return {
            ...state,
            onload: true,
            error: null
        };
    case FETCH_SHOPS_SUCCESS:
        return {
            ...state,
            onload: false,
            data: action.payload
        };
    case FETCH_SHOPS_FAILURE:
        return {
            ...state,
            onload: false,
            error: action.payload
        };
    default:
        return state;
    }
};
