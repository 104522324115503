import { BASE_PATH } from '../../const';

export const styles = {
    hotLineWrap: {
        background: '#ebedef',
        boxSizing: 'border-box',
        height: 35,
        paddingTop: 2,
        position: 'relative'
    },
    backgroundVideo: {
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        backgroundColor: 'rgba(0,0,0,0.6)',
        zIndex: 4
    },
    contentWrap: {
        maxWidth: 1180,
        margin: '0 auto',
        position: 'relative',
        width: '95%'
    },
    logoWrap: {
        position: 'absolute',
        background: '#fff',
        boxSizing: 'border-box',
        padding: [43, 11],
        top: -4,
        borderRadius: 3,
        left: 0,
        zIndex: 2,
    },
    hotLineContent: {
        boxSizing: 'border-box',
        paddingLeft: 130
    },
    cityWrap: {
        display: 'inline-block',
        verticalAlign: 'middle'
    },
    hotToRight: {
        float: 'right',
        marginTop: 7
    },
    bigImgWrap: {
        boxShadow: 'inset 0px 57px 14px -18px rgba(0,0,0,0.25)',
        padding: [25, 0, 110],
        boxSizing: 'border-box',
        overflow: 'hidden',
        position: 'relative'
    },
    menuWrap: {
        textAlign: 'right'
    },
    showInTheForeground: {
        position: 'relative',
        zIndex: 3
    },
    carSelect: {
        display: 'inline-block',
        verticalAlign: 'middle'
    },
    buttonWrap: {
        display: 'inline-block',
        verticalAlign: 'middle',
        marginLeft: 35
    },
    searchWrap: {
        display: 'inline-block',
        verticalAlign: 'middle',
        width: 18,
        marginLeft: 30
    },
    homeNavigationBar: {
        marginTop: 105,
        width: '25%'
    },
    brandWrap: {
        marginBottom: 55
    },
    searchShopWrap: {
        margin: '8px 0'
    },
    learnMoreWrap: {
        position: 'relative'
    },
    learnMore: {
        position: 'absolute',
        bottom: 0,
        right: 48,
    },
    menu: {
        display: 'inline-block',
        verticalAlign: 'middle',
        width: '57%'
    },
    title: {
        margin: [0, 0, 64]
    },
    swicher: {
        margin: [75, 0, 35],
    },
    loadMore: {
        margin: [28, 0, 20],
        'text-align': 'center'
    },
    pointImgL: {
        position: 'relative',
        margin: [45, 0, 0],
        zIndex: 9,
        '&:after': {
            content: '""',
            display: 'block',
            background: `url(${BASE_PATH}/img/pointImg.png)`,
            width: 283,
            height: 231,
            position: 'absolute',
            top: -41,
            left: -135,
            zIndex: -1
        }
    },
    aboutWrap: {
        width: '45%',
        marginTop: 80,
        background: '#012b39',
        padding: [35, 0, 30],
        boxSizing: 'border-box',
        textAlign: 'right',
        position: 'relative',
        zIndex: 2
    },
    about: {
        width: 450,
        display: 'inline-block',
        textAlign: 'left',
        paddingRight: '11%',
        boxSizing: 'border-box'
    },
    video: {
        width: '74%',
        float: 'right',
        marginTop: -331,
        position: 'relative',
        zIndex: 1
    },
    secondBanner: {
        marginTop: 187
    },
    bigPointBlock: {
        background: `url(${BASE_PATH}/img/pointImg.png)`,
        height: 231,
        marginTop: -200
    },
    progressBlock: {
        padding: [45, 0, 100],
        boxSizing: 'border-box',
        background: '#012b39',
        marginTop: 40,
        textAlign: 'center'
    },
    progressTitle: {
        display: 'inline-block',
        marginBottom: 30
    },
    progressBlocks: {
        display: 'flex',
        justifyContent: 'space-between',
        flexWrap: 'wrap',
        textAlign: 'left'
    },
    progressItem: {
        width: '42%',
        marginTop: 42
    },
    subscription: {
        height: 231,
        background: `url(${BASE_PATH}/img/pointImg.png)`,
        paddingTop: 35,
        boxSizing: 'border-box'
    },
    subscriptionBlock: {
        background: '#012b39'
    },
    footer: {
        marginTop: 20,
        borderTop: '1px solid #e6e8ea',
        borderBottom: '1px solid #e6e8ea',
        padding: [60, 0, 50],
        boxSizing: 'border-box'
    },
    footerMenuWrap: {
        display: 'flex',
        justifyContent: 'space-between',
        flexWrap: 'wrap',
    },
    footerMenu: {
        width: '12%'
    },
    footerMenuAbout: {
        width: '15%'
    },
    social: {
        marginTop: 35
    },
    afterFooterInform: {
        margin: [25, 0, 100],
        display: 'flex',
        justifyContent: 'space-between',
        flexWrap: 'wrap'
    },
    afterFooterL: {
        width: '74%',
        display: 'flex',
        justifyContent: 'space-between',
        flexWrap: 'wrap',
        '& a': {
            '&:hover': {
                textDecoration: 'underline'
            }
        }
    },
    afterFooterLink: {
        color: '#0b2431',
        textDecoration: 'none',
        fontSize: 13
    },
    LKWrap: {
        width: '14%'
    },
    '@media screen and (max-width: 1280px)': {
        homeNavigationBar: {
            width: '30%'
        },
        buttonWrap: {
            marginLeft: 25
        },
        searchWrap: {
            marginLeft: 20
        },
        menu: {
            width: '58%'
        },
        about: {
            width: '86%'
        },
        secondBanner: {
            marginTop: '13%'
        },
        footerMenuAbout: {
            width: '16%'
        },
        LKWrap: {
            width: '16%'
        },
        afterFooterL: {
            width: '80%'
        },
        hotLineContent: {
            paddingLeft: 105
        }
    },
    '@media screen and (max-width: 1000px)': {
        menu: {
            width: '44%'
        },
        homeNavigationBar: {
            width: '37%'
        },
        loadMore: {
            marginBottom: 40
        },
        about: {
            margin: [0, 'auto'],
            padding: 0,
            display: 'block'
        },
        aboutWrap: {
            width: '95%',
            margin: [30, 'auto', 0]
        },
        video: {
            float: 'none',
            margin: 0,
            width: '100%'
        },
        footerMenuAbout: {
            width: '26%'
        },
        footerMenu: {
            width: '26%',
            marginBottom: 25
        },
        footer: {
            paddingBottom: 25
        },
        afterFooterInform: {
            marginBottom: 50
        }
    }
};
