export const styles = {
    tireImageWrap: {
        position: 'relative',
        outline: 'none',
        width: '100%'
    },
    wrap: {
        display: 'inline-block',
        verticalAlign: 'middle',
        textDecoration: 'none',
        background: '#012b39',
        boxSizing: 'border-box',
        width: '100%',
        height: '100%',
        position: 'relative',
        overflow: 'hidden',
        paddingTop: '37%',
    },
    imageBlock: {
        position: 'absolute',
        top: 0,
        transition: 'all 0.6s ease',
        width: '100%',
        height: '100%'
    },
    image: {
        display: 'block',
        height: '100%',
        left: '50%',
        position: 'absolute',
        transform: 'translateX(-50%)',
    },
    tireImage: {
        display: 'block',
        maxHeight: 310,
        position: 'absolute',
        bottom: '-31%',
        left: '50%',
        transform: 'translateX(-50%)',
        zIndex: 1,
        transition: 'all 0.6s ease'
    },
    active: {
        '& $imageBlock': {
            top: '-76%'
        },
        '& $content': {
            transform: 'translateY(0)',
        },
        '& $tireImage': {
            bottom: '77%',
        }
    },
    content: {
        padding: '7.1%',
        color: '#fff',
        fontFamily: 'Arial, sans-serif',
        position: 'relative',
        left: 0,
        transform: 'translateY(100%)',
        transition: 'all 0.6s ease',
        width: '100%',
        boxSizing: 'border-box'
    },
    logoImageWrap: {
        height: 22,
    },
    logoImage:{
        display: 'block',
        margin: [0, 'auto'],
        maxWidth: '87%',
        maxHeight: 22
    },
    title: {
        fontSize: 15,
        margin: '8% 0 2%',
        height: 34,
        overflow: 'hidden',
        display: 'flex',
        alignItems: 'flex-end'
    },
    list:{
        margin: '0 0 7%',
        padding: 0,
        height: 208,
        overflow: 'hidden'
    },
    listItem:{
        margin: 0,
        padding: '2% 4%',
        fontSize: 14,
        lineHeight: 1.15,
        listStyle: 'none',
        position: 'relative',
        '&:after': {
            content: '""',
            width: 3,
            height: 3,
            position: 'absolute',
            left: 0,
            top: 11,
            background: '#fff',
            borderRadius: '50%',
        },
    },
    link: {
        border: '2px solid #fff',
        display: 'inline-block',
        padding: '4% 12% 4% 8%',
        boxSizing: 'border-box',
        color: '#fff',
        textTransform: 'uppercase',
        textDecoration: 'none',
        fontSize: 14,
        transition: 'opacity 0.4s',
        position: 'relative',

        '&:hover': {
            opacity: 0.7,
        },

        '&:after': {
            content: '""',
            width: 6,
            height: 6,
            position: 'absolute',
            right: '9%',
            top: '50%',
            transform: 'translateY(-50%) rotate(-45deg)',
            border: '2px solid #fff',
            borderTop: 0,
            borderLeft: 0,
        }
    },
    '@media screen and (max-width: 1280px)': {
        title: {
            fontSize: '1.17vw',
        },
        listItem:{
            fontSize: '1.09vw',
            '&:after': {
                top: '50%',
                marginTop: -1
            }
        },
        link: {
            fontSize: '1.09vw',
            '&:after': {
                width: '0.5vw',
                height: '0.5vw',
            }
        },
    },
    logoTopImage: {
        position: 'absolute',
        top: '12%',
        zIndex: 1,
        width: '81%',
        height: 37,
        left: '50%',
        transform: 'translateX(-50%)'
    },
    winter: {
        '& $logoTopImage': {
            height: 'auto',
            width: '90%'
        }
    },
    '@media screen and (max-width: 768px)': {
        title: {
            fontSize: '5.3vw'
        },
        listItem: {
            fontSize: '3.8vw',
        },
        link: {
            fontSize: '3.1vw'
        },
        tireImageWrap: {
            padding: [0, '2.2vw'],
            pointerEvents: 'none',
            height: '100%',
            boxSizing: 'border-box'
        },
        content: {
            transform: 'translateY(100%)'
        },
        active: {
            '& $content': {
                transform: 'translateY(0)'
            }
        },
        tireImage: {
            maxHeight: '30%',
            width: 'auto',
            maxWidth: '46%'
        }
    }
};
