import React from 'react';
import injectSheet from 'react-jss';
import { styles } from './styles.js';
import FilterSelect from '../FilterSelect';

const TabBodyTemp = ({ classes, ...props }) => {
    return (
        <div className={classes.filterWrap}>
            {
                props.items.map(item => {
                    return(
                        <div className={classes.filter} key={item.id}>
                            <FilterSelect
                                placeholder={item.placeholder}
                                options={item.options}
                                disabled={item.disabled}
                                point={item.point}
                                id={item.id}
                                type={item.type}
                                pushTo={item.pushTo}
                                link={item.link}
                                handleChange={props.handleChange}
                            />
                        </div>
                    );
                })
            }
        </div>
    );
};

export default injectSheet(styles)(TabBodyTemp);
