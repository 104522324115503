import React from 'react';
import { connect } from 'react-redux';
import Swicher from '../../components/Swicher';
import { setSeason } from '../../actions/tires';

class SwicherContainer extends React.Component {

    onChange(value){
        this.props.setSeason(value);
    }

    render() {
        return <Swicher selected={this.props.season} onChange={this.onChange.bind(this)}/>;
    }
}

const mapDispatchToProps = {
    setSeason,
};

const mapStateToProps = state => ({
    season: state.tires.season,
});

export default connect(mapStateToProps, mapDispatchToProps)(SwicherContainer);
