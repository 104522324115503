import topBannerService from '../services/topBanner';
export const FETCH_TOP_BANNER_START = 'FETCH_TOP_BANNER_START';
export const FETCH_TOP_BANNER_SUCCESS = 'FETCH_TOP_BANNER_SUCCESS';
export const FETCH_TOP_BANNER_FAILURE = 'FETCH_TOP_BANNER_FAILURE';

export const fetchTopBanner = () => async dispatch => {
    dispatch({ type: FETCH_TOP_BANNER_START });
    try {
        const banner = await topBannerService.get();
        dispatch({
            type: FETCH_TOP_BANNER_SUCCESS,
            payload: banner.result,
        });
    } catch (error) {
        dispatch({
            type: FETCH_TOP_BANNER_FAILURE,
            payload: error
        });
    }
};

