import React from 'react';
import injectSheel from 'react-jss';
import {styles} from './styles';

const TitleTemp = ({classes, children, ...props}) => {
    return(
        <div className={classes.title}>
            {children}
        </div>
    )
};

export default injectSheel(styles)(TitleTemp)