import React from 'react';
import { connect } from 'react-redux';
import device from '../../helpers/device';
import { delay } from '../../helpers';
import { getCookie, setCookie } from '../../helpers/cookie';
import { setAPPMode, setCity } from '../../actions/options';
import { fetchCities } from '../../actions/cities';
import { fetchShops } from '../../actions/shops';
import { fetchTopBanner } from '../../actions/topBanner';

class Main extends React.PureComponent {

    constructor(){
        super();
        this.defaultCity = 1;
    }

    async componentDidMount() {
        this.willResize();
        window.addEventListener('resize', this.willResize.bind(this));
        const cities = await this.props.fetchCities();
        let cityId = getCookie('city_id');
        if(!cityId){
            setCookie('city_id', this.defaultCity);
            cityId = this.defaultCity;
        }
        if(cities){
            const city = cities.find(el => Number(el.id) === Number(cityId));
            this.props.setCity(city.title);
        }
        this.props.fetchShops();
        this.props.fetchTopBanner();
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.willResize.bind(this));
    }

    willResize() {
        delay(() => {
            this.props.setAPPMode(device(window));
        });
    }

    render() {
        return null;
    }
}

const mapDispatchToProps = {
    setAPPMode,
    fetchCities,
    fetchShops,
    fetchTopBanner,
    setCity
};

const mapStateToProps = state => ({
    mode: state.options.mode,
});

export default connect(mapStateToProps, mapDispatchToProps)(Main);
