import React, { Component } from 'react';
import HomeTemp from './template';
import HomeTempMobile from './template.mobile';
import constants from '../../const';

export default class Home extends Component{
    render(){
        if(this.props.mode === constants.MOBILE){
            return <HomeTempMobile
                menuVisibility={this.props.menuVisibility}
                videoVisibility={this.props.videoVisibility}
                fixedHead={this.props.fixedHead}
            />;
        }
        return <HomeTemp
            searchVisibility={this.props.searchVisibility}
            videoVisibility={this.props.videoVisibility}
        />;
    }
}
