import {
    FETCH_CITIES_START,
    FETCH_CITIES_SUCCESS,
    FETCH_CITIES_FAILURE
} from './../actions/cities';

const initialState = {
    onload: false,
    error: null,
    data: []
};

export default (state = initialState, action) => {
    switch (action.type) {
    case FETCH_CITIES_START:
        return {
            ...state,
            onload: true,
            error: null
        };
    case FETCH_CITIES_SUCCESS:
        return {
            ...state,
            onload: false,
            data: action.payload
        };
    case FETCH_CITIES_FAILURE:
        return {
            ...state,
            onload: false,
            error: action.payload
        };
    default:
        return state;
    }
};
