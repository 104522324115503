export const PROD = 'https://www.bridgestone.ru';
export default {
    urlAPI: {
        tires: '/main-page/getTyres/',
        cities: '/local/components/kodix/json/ajax.php?show_page_exec_time=N&show_include_exec_time=N&show_sql_stat=N&bitrix_include_areas=N',
        shops: '/local/components/kodix/button.location/ajax.php?show_page_exec_time=N&show_include_exec_time=N&show_sql_stat=N&bitrix_include_areas=N',
        subscribe: '/ajax/subscribe.php',
        news: '/main-page/getTopNews/',
        topBanner: '/main-page/getTopBanner/',
    }
};
