import React from 'react';
import injectSheet from 'react-jss';
import { styles } from './styles.js';

const TiresItemTemplate = ({ classes, ...props }) => {

    return (
        <div className={[classes.tireImageWrap, (props.isHover || props.index === props.slideIndex) ? classes.active : ' ', props.content.season === 'winter' ? classes.winter : ' '].join(' ')} onMouseEnter={props.changeHover} onMouseLeave={props.changeHover}>
            <img src={props.tireImageSrc} className={classes.tireImage} alt=''/>
            <div className={classes.wrap}>
                <div className={classes.imageBlock}>
                    <img src={props.logoSrc} className={classes.logoTopImage} alt=''/>
                    <img src={props.topImageSrc} className={classes.image} alt=''/>
                </div>
                <div className={classes.content}>
                    <div className={classes.logoImageWrap}><img src={props.logoHideSrc} className={classes.logoImage} alt=''/></div>
                    <div className={classes.title}>{props.content.title}</div>
                    <ul className={classes.list}>
                        {props.content.info.length &&
                            props.content.info.map((item, index) => {
                                return <li key={index} className={classes.listItem}>{item}</li>
                            })
                        }
                    </ul>
                    <a href={props.link} className={classes.link} >Подробнее</a>
                </div>
            </div>
        </div>
    )
};

export default injectSheet(styles)(TiresItemTemplate);
