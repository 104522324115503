import React from 'react';
import { connect } from 'react-redux';
import Home from '../../components/Home/index'

class Index extends React.Component {
    render() {
        return (
            <Home
                mode={this.props.mode}
                searchVisibility={this.props.searchVisibility}
                menuVisibility={this.props.menuVisibility}
                videoVisibility={this.props.videoVisibility}
                fixedHead={this.props.fixedHead}
            />
        );
    }
}

const mapStateToProps = state => ({
    mode: state.options.mode,
    menuVisibility: state.options.menuVisibility,
    searchVisibility: state.options.searchVisibility,
    videoVisibility: state.options.videoVisibility,
    fixedHead: state.options.fixedHead
});

export default connect(mapStateToProps)(Index);