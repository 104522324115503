import {
    UPDATE_FILTER_ITEMS
} from './../actions/filters';
import { BRAND_LIST, WIDTH_LIST } from '../const.filters';

const initialState = {
    onload: false,
    error: null,
    data: {
        car: [
            {
                title: 'Бренд',
                id: 'brand',
                items: [...BRAND_LIST],
                point: '/getModels/',
                pushTo: 'models',
                type:'car'
            },
            {
                title: 'Модель',
                items: [],
                id: 'model',
                idDb: 'models',
                pushTo: 'years',
                point: '/getYears/',
                type:'car'
            },
            {
                title: 'Год выпуска',
                items: [],
                id: 'year',
                idDb: 'years',
                relationship: 'model',
                point: '/getTyres/',
                type:'car'
            }
        ],
        tyre: [
            {
                title: 'Ширина',
                id: 'width',
                items: [...WIDTH_LIST],
                point: '/getHeights/',
                pushTo: 'heights',
                link: 'w',
                type:'tyre'
            },
            {
                title: 'Высота',
                id: 'height',
                idDb: 'heights',
                items: [],
                point: '/getDiameters/',
                pushTo: 'diameters',
                link: 'p',
                type:'tyre'
            },
            {
                title: 'Диаметр',
                id: 'diameter',
                idDb: 'diameters',
                items: [],
                relationship: 'height',
                point: '/getIndexes/',
                pushTo: 'indexes',
                link: 'r',
                type:'tyre'
            },
            {
                title: 'Индекс',
                id: 'index',
                idDb: 'indexes',
                items: [],
                relationship: 'diameter',
                point: '/getSigns/',
                pushTo: 'signs',
                type:'tyre'
            },
            {
                title: 'Символ',
                id: 'sign',
                idDb: 'signs',
                items: [],
                relationship: 'index',
                point: '/getTyres/',
                type:'tyre'
            },
        ],
    }
};

export default (state = initialState, action) => {
    switch (action.type) {
    case UPDATE_FILTER_ITEMS:
        return {
            ...state,
            data: action.payload
        };
    default:
        return state;
    }
};
