import { BASE_PATH } from '../../const';
export const styles = {
    tiresWrap: {
        position: 'relative',
        marginTop: 35,
        flexWrap: 'wrap',
        '& .slick-slider': {
            zIndex: 8,
            '&:before': {
                content: '""',
                display: 'block',
                background: '#fff',
                width: '100%',
                position: 'absolute',
                left: 0,
                top: '100%',
                height: 184,
                zIndex: 9
            }
        },
        '& .slick-slide': {
            padding: [0, 7],
            boxSizing: 'border-box',
            '& *': {
                outline: 'none'
            }
        },
        '& .slick-list': {
            overflow: 'visible'
        },
        '& .slick-next': {
            top: 0,
            left: '100%',
            width: 500,
            height: '150%',
            zIndex: 8,
            position: 'absolute',
            background: '#fff',
            right: 'auto',
            transform: 'none',
            cursor: 'default'
        },
        '& .slick-prev': {
            top: 0,
            width: 500,
            height: '150%',
            zIndex: 8,
            position: 'absolute',
            background: '#fff',
            right: '100%',
            left: 'auto',
            transform: 'none',
            cursor: 'default',
        },
        '& .slick-dots': {
            zIndex: 9,
            '& li': {
                width: 36,
                height: 5,
                '& button': {
                    width: 36,
                    height: 5,
                    background: '#d3d3d3',
                    padding: 0,
                    '&:before': {
                        display: 'none'
                    }
                }
            },
            '& .slick-active': {
                '& button': {
                    background: '#e6131b'
                }
            }
        }
    },
    pointsImgR: {
        '&:after': {
            content: '""',
            display: 'block',
            background: `url(${BASE_PATH}/img/pointImg.png)`,
            width: 283,
            height: 231,
            position: 'absolute',
            top: -54,
            right: -128,
            zIndex: -1
        }
    },
    tires: {
        display: 'flex',
        verticalAlign: 'top',
        width: '24%',
        paddingLeft: '1.3%'
    },
    showAll: {
        margin: [45, 0, 0],
        textAlign: 'center',
        position: 'relative',
        zIndex: 9
    },
    '@media screen and (max-width: 1000px)': {
        showAll: {
            marginTop: 40
        },
    }
};
