export const styles = {
    wrap: {
        textAlign: 'center',
        transition: 'all 0.4s',
    },
    switchButton: {
        fontSize: 23,
        fontWeight: 600,
        color: '#0d0d0d',
        border: 0,
        background: 'none',
        outline: 'none',
        verticalAlign: 'middle',
        position: 'relative',
        padding: [1, 0],
        transition: 'all 0.6s ease',
        '&:hover': {
            color: '#000!important'
        },
        '&:after': {
            content: '""',
            position: 'absolute',
            bottom: 0,
            left: 0,
            width: '100%',
            border: '1px dashed #e6131b',
            display: 'none',
        }
    },
    checkSlider: {
        position: 'relative',
        display: 'inline-block',
        verticalAlign: 'middle',
        width: 65,
        height: 20,
        borderRadius: 10,
        background: '#ebedef',
        boxShadow: 'inset 0 1px 2px rgba(0, 0, 0, 0.1)',
        cursor: 'pointer',
        margin: [0,15],

        '&:after': {
            content: '""',
            position: 'absolute',
            top: '50%',
            left: 0,
            transform: 'translateY(-50%)',
            width: 33,
            height: 33,
            borderRadius: '50%',
            background: '#e6131b',
        }
    },
    switchSummer: {},
    switchWinter: {},
    summer: {
        '& $switchWinter': {
            color: '#949da5',
            cursor: 'pointer',
            '&:after': {
                display: 'block',
            }
        },
        '& $checkSlider:after' : {
            left: 'auto',
            right: 0,
        },
    },
    winter: {
        '& $switchSummer': {
            color: '#949da5',
            cursor: 'pointer',
            '&:after': {
                display: 'block',
            }
        },
    },
}
