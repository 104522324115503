let _BASE_PATH;
if (process.env.NODE_ENV === 'production') {
    _BASE_PATH = '/app';
} else {
    _BASE_PATH = '';
}

export const BASE_PATH = _BASE_PATH;

const constants = {
    MOBILE: 'mobile',
    DESKTOP: 'desktop',
    MOBILE_POINT: 768,
};

export default constants;

export const localStorePrefix = 'BRIDGESTONE.APP.';
