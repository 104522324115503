import newsService from '../services/news';
export const FETCH_NEWS_START = 'FETCH_NEWS_START';
export const FETCH_NEWS_SUCCESS = 'FETCH_NEWS_SUCCESS';
export const FETCH_NEWS_FAILURE = 'FETCH_NEWS_FAILURE';

export const fetchNews = () => async dispatch => {
    dispatch({ type: FETCH_NEWS_START });
    try {
        const news = await newsService.get();
        dispatch({
            type: FETCH_NEWS_SUCCESS,
            payload: news.result.items,
        });
    } catch (error) {
        dispatch({
            type: FETCH_NEWS_FAILURE,
            payload: error
        });
    }
};

