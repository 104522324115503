class Entity {
    constructor(AsnaAPI, config) {
        const { urlAPI } = config;
        this.api = new AsnaAPI(urlAPI, window.localStorage);
    }
    async get(query = {}, point = '') {
        if (this.storeEntity) {
            return await this.api.get(this.storeEntity, query, point);
        }
    }
    async post(data = {}, options = {}) {
        if (this.storeEntity) {
            return await this.api.post(this.storeEntity, data, options);
        }
    }
}

export default Entity;
