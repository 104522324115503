import React from 'react';
import { styles } from './styles';
import injectSheet from 'react-jss';
import Select from 'react-select';

const FilterSelectTemp = ({classes, ...props}) => {
    return(
        <div className={classes.filterSelect}>
            <Select
                options={[
                    {value: '', label: props.placeholder},
                    ...props.options
                ]}
                placeholder={props.placeholder}
                onChange={value => props.handleChange(value, props.id, props.type, props.point, props.pushTo, props.link)}
            />
        </div>
    );
};

export default injectSheet(styles)(FilterSelectTemp);
